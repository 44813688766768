<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-04-08 16:52:16
 * @LastEditTime: 2021-05-21 15:34:24
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="album">
    <LogoTitle msg="活动图集"></LogoTitle>
    <div class="list">
      <AlbumCard
        v-for="item in list"
        :info="item"
        @tagClick="tagClick"
        :key="item.id"
      ></AlbumCard>
    </div>
    <div class="sljr-pagination">
      <SinglePagination
        :total="total"
        v-if="total <= pageSize"
      ></SinglePagination>
      <el-pagination
        :hide-on-single-page="isHide"
        :page-size="pageSize"
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :total="total"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: 1,//当前页数
      pageSize: 9,//每页显示条目个数
      total: 0,//总条目数
      isHide: true,//只有一页时是否隐藏
      list: [],
      tagsId: ''
    }
  },
  created () {
    document.title = "活动图集"
    this.getList()
  },
  methods: {
    /**
   * @description: 活动图集列表
   * @param  {categoryId: '1376792129237585921',categoryType,pageNo,pageSize}
   * @return {*}
   */
    getList () {
      let params = {
        categoryId: '1376792888784097281',
        categoryType: '1',
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        tagsId: this.tagsId
      }
      this.$api.getContentList(params).then((res) => {
        this.total = res.data.data.total
        this.list = res.data.data.rows
      })
    },
    /**
 * @description: 标签筛选
 * @param  {*}
 */
    tagClick (data) {
      console.log('父组件接收tagsId：', data)
      this.tagsId = data;
      this.currentPage = 1;
      this.getList();
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList()
      document.body.scrollIntoView()
    }
  },
}
</script>

<style scoped lang='scss'>
.album {
  .album-card {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  .sljr-pagination {
    margin-top: 25px;
  }
}
</style>